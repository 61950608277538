define("discourse/plugins/discourse-chatbot/discourse/templates/components/chatbot-user-preferences", ["exports", "@ember/template-factory"], function (_exports, _templateFactory) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = (0, _templateFactory.createTemplateFactory)(
  /*
    <label class="control-label">{{i18n "chatbot.user_prefs.title"}}</label>
  <PreferenceCheckbox
    @labelKey="chatbot.user_prefs.prefer_no_quickchat_pm_popup"
    @checked={{this.model.custom_fields.chatbot_user_prefs_disable_quickchat_pm_composer_popup_mobile}}
  />
  */
  {
    "id": "W9YDxtea",
    "block": "[[[10,\"label\"],[14,0,\"control-label\"],[12],[1,[28,[35,0],[\"chatbot.user_prefs.title\"],null]],[13],[1,\"\\n\"],[8,[39,1],null,[[\"@labelKey\",\"@checked\"],[\"chatbot.user_prefs.prefer_no_quickchat_pm_popup\",[30,0,[\"model\",\"custom_fields\",\"chatbot_user_prefs_disable_quickchat_pm_composer_popup_mobile\"]]]],null]],[],false,[\"i18n\",\"preference-checkbox\"]]",
    "moduleName": "discourse/plugins/discourse-chatbot/discourse/templates/components/chatbot-user-preferences.hbs",
    "isStrictMode": false
  });
});