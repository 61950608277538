define("discourse/plugins/discourse-chatbot/discourse/templates/connectors/topic-above-posts/link-composer-raiser", ["exports", "@ember/template-factory"], function (_exports, _templateFactory) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = (0, _templateFactory.createTemplateFactory)(
  /*
    <ComposerRaiser @model={{this.model}} />
  */
  {
    "id": "R7j2Fwmf",
    "block": "[[[8,[39,0],null,[[\"@model\"],[[30,0,[\"model\"]]]],null]],[],false,[\"composer-raiser\"]]",
    "moduleName": "discourse/plugins/discourse-chatbot/discourse/templates/connectors/topic-above-posts/link-composer-raiser.hbs",
    "isStrictMode": false
  });
});